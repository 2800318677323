import axios from "@/ip_config";
import moment from "moment";

const apiInvite = {
  namespaced: true,

  state: () => ({
    inviteLength: 0,
    invitationsCard: [],
    invitationsTable: [],
    loadingInvitation: false,
  }),

  mutations: {
    mutationCard(state, mutationCard) {
      state.invitationsCard = mutationCard;
    },
    mutationTable(state, mutationTable) {
      state.invitationsTable = mutationTable;
    },
    mutationloadingInvitation(state, mutationloadingInvitation) {
      state.loadingInvitation = mutationloadingInvitation;
    },
    mutationLength(state, mutationLength) {
      state.inviteLength = mutationLength;
    },
  },

  actions: {
    getInvite({ commit }) {
      commit("mutationloadingInvitation", true);
      moment.locale("id");

      var panjang = 0;
      var invites = [];

      axios
        .get("users/invitationToKAP", {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((res) => {
          let q = res.data;

          res.data.forEach((el, id) => {
            q[id].createdAt = moment(el.createdAt).format("L");
            invites.push(el);
          });

          q = res.data.sort((a, b) => b.id - a.id);

          panjang += res.data.length;

          axios
            .get("users/invitationToClient", {
              headers: {
                token: localStorage.getItem("token"),
              },
            })
            .then((res) => {
              let w = res.data;

              res.data.forEach((el, id) => {
                w[id].createdAt = moment(el.createdAt).format("L");
                invites.push(el);
              });

              w = res.data.sort((a, b) => b.id - a.id);

              panjang += res.data.length;

              axios
                .get("users/invitationToProject", {
                  headers: {
                    token: localStorage.getItem("token"),
                  },
                })
                .then((res) => {
                  let e = res.data;

                  res.data.forEach((el, id) => {
                    e[id].createdAt = moment(el.createdAt).format("L");
                    invites.push(el);
                  });

                  e = res.data.sort((a, b) => b.id - a.id);

                  panjang += res.data.length;

                  commit("mutationCard", invites);
                  commit("mutationTable", invites);
                  commit("mutationLength", panjang);
                  commit("mutationloadingInvitation", false);
                });
            });
        });
    },
  },
};

export default apiInvite;
