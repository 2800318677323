import Vue from 'vue'
import Vuex from 'vuex'
import apiUser from "./API_user"
import apiListKAP from "./API_list_KAP_user"
import apiInvite from "./API_invite"
import apiProject from "./API_project"
import apiProfileKAP from "./API_profile_KAP"
import apiClient from "./API_client"
import apiListClient from "./API_list_client"
import apiListProject from "./API_list_project"



Vue.use(Vuex)

export default new Vuex.Store({

    modules: {
        apiUser,
        apiListKAP,
        apiInvite,
        apiProject,
        apiProfileKAP,
        apiClient,
        apiListClient,
        apiListProject
    },
})
