<template>
  <div>
    <section class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
      <b-container fluid>
        <b-row>
          <b-col md="12" lg="12" xl="6">
            <div class="wrapper">
              <div class="title">
                <span>KAPMOBILE.ID</span>
              </div>

              <div class="msg">
                <span>{{ pesan }}</span>
              </div>
              <div class="form">
                <form v-on:submit.prevent="doLogin">
                  <b-form-input
                    class="inputs"
                    v-model="email"
                    placeholder="Email"
                    type="email"
                    @input="checking"
                  ></b-form-input>
                  <b-form-input
                    class="inputs"
                    v-model="password"
                    placeholder="Password"
                    type="password"
                    @input="checking"
                  ></b-form-input>

                  <div class="button-wrapper">
                    <div class="forgot">
                      <span
                        class="biru"
                        @click="() => this.$router.push('/forgot_password')"
                        >Forgot Password</span
                      >
                    </div>
                    <b-button
                      @click="doLogin"
                      :disabled="checked == false || loading == true"
                    >
                      <span v-if="loading == false">Login</span>
                      <white-spinner v-else />
                    </b-button>

                    <div class="sign-in">
                      <span
                        >Belum punya akun?
                        <span
                          class="biru"
                          @click="() => this.$router.push('/register')"
                          >sign up</span
                        ></span
                      >
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </b-col>

          <b-col xl="6" class="d-md-none d-lg-none d-xl-block pr-0">
            <div class="wrapper2">
              <div style="width: 100%">
                <hooper
                  :itemsToShow="1.2"
                  :autoPlay="true"
                  :playSpeed="4000"
                  :centerMode="true"
                  :trimWhiteSpace="true"
                  :infiniteScroll="true"
                  :wheelControl="false"
                >
                  <slide v-for="data in iklan" :key="data.i">
                    <center>
                      <a :href="data.link_URL" target="blank">
                        <img
                          style="
                            border-radius: 7px;
                            max-width: 90%;
                            min-height: 90%;
                          "
                          :src="data.image"
                          alt=""
                        />
                      </a>
                    </center>
                  </slide>
                  <hooper-navigation
                    style="background-color: #8f8f8f !important"
                    slot="hooper-addons"
                  ></hooper-navigation>
                </hooper>
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row class="d-xl-none mt-5">
          <b-col md="10" lg="10" offset-md="1" offset-lg="1">
            <hooper
              :itemsToShow="1.2"
              :autoPlay="true"
              :playSpeed="4000"
              :centerMode="true"
              :trimWhiteSpace="true"
              :infiniteScroll="true"
              :wheelControl="false"
            >
              <slide v-for="data in iklan" :key="data.i">
                <center>
                  <a :href="data.link_URL" target="blank">
                    <img
                      style="border-radius: 7px; max-width: 90%; height: 90%"
                      :src="data.image"
                      alt=""
                    />
                  </a>
                </center>
              </slide>
              <hooper-navigation
                style="background-color: #8f8f8f !important"
                slot="hooper-addons"
              ></hooper-navigation>
            </hooper>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section class="d-sm-block d-md-none d-lg-none d-xl-none">
      <b-container>
        <b-row>
          <b-col cols="12" sm="12">
            <div
              style="
                width: 100%;
                height: 100vh;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <h4 class="text-center">
                <strong>Does not support mobile version</strong>
              </h4>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
// import axios from "axios";
import axiosBE from "@/ip_config";
import ip_ads from "@/ip_ads";
import whiteSpinner from "../../components/spinner/white-spinner.vue";
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import "hooper/dist/hooper.css";

export default {
  components: { whiteSpinner, Hooper, Slide, HooperNavigation },
  data() {
    return {
      email: "",
      password: "",
      pesan: "",
      loading: false,
      checked: false,
      iklan: [],
    };
  },
  beforeMount() {
    ip_ads.get("promotion").then((res) => {
      if (res.data.meta.status == "success") {
        this.iklan = res.data.data.promotion;
      }
    });
  },
  created() {
    if (localStorage.getItem("token") || localStorage.getItem("token") != "") {
      this.$router.push("/main_dashboard");
    }
    if (!localStorage.getItem("token") && localStorage.getItem("email")) {
      this.email = localStorage.getItem("email");
    }
  },
  methods: {
    checking() {
      let vm = this;
      if (vm.email && vm.password) {
        vm.checked = true;
      } else {
        vm.checked = false;
      }
    },

    doLogin() {
      let vm = this;
      vm.loading = true;

      axiosBE
        .post("users/login", {
          email: vm.email,
          password: vm.password,
        })
        .then((res) => {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("email", vm.email);
          if (res.data.message) {
            vm.pesan = res.data.message;
          } else if (res.data.verifikasi == 1) {
            this.$router.push("/main_dashboard");
          } else {
            axiosBE
              .post(
                "OTP/kirimUlangOTP2",
                {},
                {
                  headers: {
                    token: localStorage.getItem("token"),
                  },
                }
              )
              .then(() => {
                this.$router.push("/otp");
              });
          }
          vm.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");

$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white2": #fff,
);

.d-xl-none .hooper {
  height: 400px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 10%;
  .title {
    font-family: "nunito", sans-serif;
    font-weight: 700;
    font-size: 48px;
    margin-top: 8%;
    margin-bottom: 4%;
    color: map-get($color, "blue");
  }

  .msg {
    font-family: "nunito", sans-serif;
    color: map-get($color, "red");
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
  }

  .form {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 4%;
    .inputs {
      height: 57px;
      font-family: "nunito", sans-serif;
      font-weight: 700;
      font-size: 16px;
      margin: 2% 0;
      border-radius: 6px;
    }
  }
  .button-wrapper {
    .btn {
      width: 100%;
      height: 57px;
      font-family: "nunito", sans-serif;
      font-weight: 700;
      text-transform: capitalize;
      font-size: 16px;
      border-radius: 6px;
      background-color: map-get($color, "blue");
      border: 1px solid map-get($color, "blue");
      box-shadow: 0px 20px 25px -10px rgba(15, 93, 210, 0.25);
      margin: 1% 0;
    }
    .forgot {
      font-family: "nunito", sans-serif;
      text-transform: capitalize;
      color: map-get($color, "black");
      font-weight: 300;
      font-size: 20px;
      color: map-get($color, "blue");
      cursor: pointer;
      font-weight: 700;
      text-transform: capitalize;
      cursor: pointer;
      text-align: end;
      margin-bottom: 10%;
    }
    .sign-in {
      font-family: "nunito", sans-serif;
      font-size: 20px;
      color: map-get($color, "black");
      font-weight: 300;
      margin-top: 10%;
      .biru {
        color: map-get($color, "blue");
        text-transform: capitalize;
        font-weight: 700;
        cursor: pointer;
      }
    }
  }
}

.wrapper2 {
  background-image: url("../../assets/vector/Background.png");
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
  // background-blend-mode: soft-light;
  background-color: #327ff2;
  height: 100vh;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  .hooper {
    height: 350px;
  }
}
</style>
