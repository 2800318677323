import axios from "@/ip_config";
import moment from "moment";

const apiUser = {
  namespaced: true,
  state: () => ({
    stateId: "",
    stateProfile: [],
    statePict: "",
  }),
  mutations: {
    mutationId(state, mutationId) {
      state.stateId = mutationId;
    },
    mutationProfile(state, mutationProfile) {
      state.stateProfile = mutationProfile;
    },
    mutationPict(state, mutationPict) {
      state.statePict = mutationPict;
    },
  },
  actions: {
    async getUser({ commit }) {
      await axios
        .get("users/profile", {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((res) => {
          commit("mutationId", res.data[0].id);

          let t = res.data[0];
          t.tanggalLahir = moment(t.tanggalLahir).format("YYYY-MM-DD");

          commit("mutationProfile", t);
          commit("mutationPict", "https://engine.kapmobile.id/" + t.foto);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};

export default apiUser;
