import axios from "@/ip_config";
import moment from "moment";

const apiListClient = {
  namespaced: true,

  state: () => ({
    listClient: [],
    loadingT: false,
  }),

  mutations: {
    mutationLoading(state, mutationLoading) {
      state.loadingT = mutationLoading;
    },
    mutationListClient(state, mutationListClient) {
      state.listClient = mutationListClient;
    },
  },

  actions: {
    getData({ commit }) {
      moment.locale("id");
      commit("mutationLoading", true);

      let x = localStorage.getItem("token");
      let f = localStorage.getItem("idKAP");

      axios
        .get("KAP/listById/" + f, {
          headers: {
            token: x,
          },
        })
        .then((res) => {
          let payload = res.data.respon;

          if (payload[0].clientid == null) {
            commit("mutationListClient", []);
          } else {
            payload.forEach((el, id) => {
              payload[id].clientUpdate = moment(el.clientUpdate).format("LLL");
              payload[id].nomer = id + 1;
              commit("mutationListClient", payload);
            });
          }
          setTimeout(() => {
            commit("mutationLoading", false);
          }, 1000);
        });
    },
  },
};

export default apiListClient;
