import axios from "@/ip_config";

const apiClient = {
  namespaced: true,
  state: () => ({
    client: [],
    loadingC: false,
    project: 0,
    tidakAktif: "",
  }),
  mutations: {
    loadingClient(state, loadingClient) {
      state.loadingC = loadingClient;
    },
    mutationClient(state, mutationClient) {
      state.client = mutationClient;
    },
    mutationProject(state, mutationProject) {
      state.project = mutationProject;
    },
    nonAktif(state, nonAktif) {
      state.tidakAktif = nonAktif;
    },
  },
  actions: {
    getData({ commit }) {
      commit("loadingClient", true);
      let x = localStorage.getItem("idClient");

      axios
        .get("client/listById/" + x, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((res) => {
          if (res.data.message == "CLIENT TIDAK AKTIF") {
            commit("nonAktif", "Client Tidak Aktif");
          } else {
            commit("nonAktif", "");
            commit("mutationClient", res.data.data[0]);

            localStorage.setItem("namaClient", res.data.data[0].namaClient);

            if (res.data.data[0].namaClient.length > 13) {
              res.data.data[0].namaClient1 =
                res.data.data[0].namaClient.substring(0, 13) + "...";
            } else {
              res.data.data[0].namaClient1 = res.data.data[0].namaClient;
            }

            let q = localStorage.getItem("role");
            if (q == undefined || q == "undefined") {
              localStorage.setItem("role", res.data.jabatan[0].jabatan);
            }

            if (
              res.data.data[0].projectid == null ||
              res.data.data[0].projectid == ""
            ) {
              commit("mutationProject", 0);
            } else {
              commit("mutationProject", res.data.data.length);
            }

            setTimeout(() => {
              commit("loadingClient", false);
            }, 1500);
          }
        });
    },
  },
};

export default apiClient;
