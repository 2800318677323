import axios from "@/ip_config";
import moment from "moment";

const apiProfileKAP = {
  namespaced: true,
  state: () => ({
    profileKAP: [],
    clientLength: 0,
    loadingK: false,
    role: "",
    user: 0,
  }),
  mutations: {
    mutationKAP(state, mutationKAP) {
      state.profileKAP = mutationKAP;
    },
    loadingK(state, loadingK) {
      state.loadingK = loadingK;
    },
    clientLength(state, clientLength) {
      state.clientLength = clientLength;
    },
    mutationRole(state, mutationRole) {
      state.role = mutationRole;
    },
    mutationUser(state, mutationUser) {
      state.user = mutationUser;
    },
  },
  actions: {
    getDetails({ commit }, x) {
      commit("loadingK", true);

      axios
        .get("KAP/listById/" + x, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((res) => {
          let data = res.data.respon[0];
          commit("mutationUser", res.data.jumlahUsers);
          if (data.namaKAP.length > 14) {
            data.namaKAP1 = data.namaKAP.substring(0, 14) + "...";
          } else {
            data.namaKAP1 = data.namaKAP;
          }

          if (res.data.jabatan[0].jabatan == "adminKAP") {
            commit("mutationRole", "admin");
            localStorage.setItem("role", "admin");
          } else {
            commit("mutationRole", res.data.jabatan[0].jabatan);
          }

          if (res.data.respon[0].clientid == null) {
            commit("clientLength", 0);
          } else {
            commit("clientLength", res.data.respon.length);
          }
          res.data.respon.forEach((el) => {
            data.tanggalkap = moment(el.tanggalkap).format("L");
          });
          commit("mutationKAP", data);

          setTimeout(() => {
            commit("loadingK", false);
          }, 1500);
        });
    },
  },
};

export default apiProfileKAP;
