import axios from "@/ip_config";

const apiProject = {
  namespaced: true,
  state: () => ({
    stateProject: [],
    projectLength: 0,
    loadingP: false,
    role: "",
    tidakAktif: "",
  }),
  mutations: {
    mutationProject(state, mutationProject) {
      state.stateProject = mutationProject;
    },
    loadingProject(state, loadingProject) {
      state.loadingP = loadingProject;
    },
    mutationLength(state, mutationLength) {
      state.projectLength = mutationLength;
    },
    mutationRole(state, mutationRole) {
      state.role = mutationRole;
    },
    nonAktif(state, nonAktif) {
      state.tidakAktif = nonAktif;
    },
  },
  actions: {
    getProject({ commit }, x) {
      commit("loadingProject", true);

      axios
        .get("project/listById/" + x, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((res) => {
          if (res.data.message == "PROJECT TIDAK AKTIF") {
            commit("nonAktif", "Project Tidak Aktif");
          } else {
            commit("nonAktif", "");
            if (res.data.data[0].namaKAP.length > 12) {
              res.data.data[0].namaKAP1 =
                res.data.data[0].namaKAP.substring(0, 13) + "...";
            } else {
              res.data.data[0].namaKAP1 = res.data.data[0].namaKAP;
            }

            if (res.data.data[0].namaProject.length > 12) {
              res.data.data[0].namaProject1 =
                res.data.data[0].namaProject.substring(0, 13) + "...";
            } else {
              res.data.data[0].namaProject1 = res.data.data[0].namaProject;
            }
            commit("mutationProject", res.data.data[0]);
            commit("mutationLength", res.data.data.length);
            commit("mutationRole", res.data.jabatan[0].jabatan);

            setTimeout(() => {
              commit("loadingProject", false);
            }, 1500);
          }
        });
    },
  },
};

export default apiProject;
