import axios from "@/ip_config";
import moment from "moment";

const apiListKAP = {
  namespaced: true,
  state: () => ({
    stateKAP: [],
    loadingKAP: false,
  }),
  mutations: {
    mutationKAP(state, mutationKAP) {
      state.stateKAP = mutationKAP;
    },
    mutationLoading(state, mutationLoading) {
      state.loadingKAP = mutationLoading;
    },
  },
  actions: {
    async getKAP({ commit }) {
      commit("mutationLoading", true);
      moment.locale("id");

      let res = await axios.get("KAP/listByUser", {
        headers: {
          token: localStorage.getItem("token"),
        },
      });

      let z = res.data;

      let hardwell = [];
      let payback = [];
      let afrojack = [];

      res.data.forEach(async (el, id) => {
        if (el.namaKAP.length > 40) {
          el.namaKAP1 = el.namaKAP.substring(0, 40) + "...";
        } else {
          el.namaKAP1 = el.namaKAP;
        }

        if (el.namaKAP1.length > 14) {
          el.namaKAP2 = el.namaKAP1.substring(0, 14) + "...";
        } else {
          el.namaKAP2 = el.namaKAP;
        }

        res.data[id].theRole = "";
        let payload = await axios.get("KAP/listById/" + res.data[id].id, {
          headers: {
            token: localStorage.getItem("token"),
          },
        });

        hardwell.push(payload);

        hardwell.forEach((el, idx) => {
          hardwell[idx].data.respon.forEach(async (el, ii) => {
            if (res.data[id].id == hardwell[idx].data.respon[ii].id) {
              if (hardwell[idx].data.jabatan[0].jabatan) {
                if (hardwell[idx].data.jabatan[0].jabatan == "adminKAP") {
                  res.data[id].theRole = "Admin";
                } else {
                  res.data[id].theRole = hardwell[idx].data.jabatan[0].jabatan;
                }
              } else {
                await axios
                  .get(
                    "client/listById/" + hardwell[idx].data.respon[ii].clientid,
                    {
                      headers: {
                        token: localStorage.getItem("token"),
                      },
                    }
                  )
                  .then((payload2) => {
                    payback.push(payload2);
                    payback.forEach((el, iix) => {
                      payback[iix].data.data.forEach(async (el, ind) => {
                        if (payback[iix].data.data[ind].id == res.data[id].id) {
                          if (payback[iix].data.jabatan[0].jabatan) {
                            res.data[id].theRole =
                              payback[iix].data.jabatan[0].jabatan;
                          } else {
                            await axios
                              .get(
                                "project/listById/" +
                                  payback[iix].data.data[ind].projectid,
                                {
                                  headers: {
                                    token: localStorage.getItem("token"),
                                  },
                                }
                              )
                              .then((payload3) => {
                                afrojack.push(payload3);
                                afrojack.forEach((el, inx) => {
                                  res.data[id].theRole =
                                    afrojack[inx].data.jabatan[0].jabatan;
                                });
                              });
                          }
                        }
                      });
                    });
                  });
              }
            }
          });
        });
      });

      z.forEach((el, id) => {
        el.updatedAt = moment(el.updatedAt).format("L");
        el.nomer = id += 1;
      });

      commit("mutationKAP", z);

      commit("mutationLoading", false);
      // setTimeout(() => {
      // }, 1500);

      //     .catch((err) => {
      //     console.log(err);
      // })
    },
  },
};

export default apiListKAP;
