import Vue from "vue";
import VueRouter from "vue-router";
import login from "../views/before-login/login.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: login,
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import('../views/before-login/login.vue')
  // },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/before-login/register.vue"),
  },
  {
    path: "/forgot_password",
    name: "forgot_password",
    component: () => import("../views/before-login/forgot_password.vue"),
  },
  {
    path: "/submit_password/:email",
    name: "submit_password",
    component: () => import("../views/before-login/submit_password.vue"),
  },
  {
    path: "/invitregisKAP/:KAPId/:userId/:email",
    name: "invitregis",
    component: () => import("../views/before-login/invite.vue"),
  },
  {
    path: "/invitregisClient/:clientId/:userId/:email",
    name: "invitregisClient",
    component: () => import("../views/before-login/invite-client.vue"),
  },
  {
    path: "/invitregisProject/:projectId/:userId/:email",
    name: "invitregisProject",
    component: () => import("../views/before-login/invite-project.vue"),
  },

  // !!---------------------------AFTER LOGIN------------------------------

  {
    path: "/otp/",
    name: "otp",
    component: () => import("../views/before-login/OTP.vue"),
    meta: {
      requiredAuth: true,
    },
  },
  {
    path: "/fitur/:id",
    name: "fitur",
    component: () =>
      import("../views/after-login/Dashboard/components/NewFeaturesList.vue"),
    meta: {
      requiredAuth: true,
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/after-login/profile.vue"),
    meta: { requiredAuth: true },
  },
  {
    path: "/main_dashboard",
    name: "main_dashboard",
    component: () => import("../views/after-login/Dashboard/MainDashboard.vue"),
    meta: { requiredAuth: true },
  },
  {
    path: "/profile_kap/:id",
    name: "viewkap",
    component: () => import("../views/after-login/KAP/KAPDashboard.vue"),
    meta: { requiredAuth: true },
  },
  {
    path: "/profile_client/:id",
    name: "viewclient",
    component: () => import("../views/after-login/client/profile_client.vue"),
    meta: { requiredAuth: true },
  },
  {
    path: "/project/:id",
    name: "project",
    component: () => import("../views/after-login/project/project.vue"),
    meta: { requiredAuth: true },
  },
  {
    path: "/detail_donut/:id",
    name: "donut",
    component: () => import("../components/details/detail_donut.vue"),
    meta: { requiredAuth: true },
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {

//   if (to.matched.some(record => record.meta.requiredAuth)) {
//     if (!localStorage.getItem("token") || localStorage.getItem("token") == '' || localStorage.getItem("token") == 'undefined' || localStorage.getItem("token") == null) {
//       next({ path: '/', query: { redirect: to.fullPath } })
//     } else {
//       next()
//     }
//   } else {
//     next()
//   }
// })

import axios from "axios";
import { ip_server } from "@/ip_config";

router.beforeEach(async function(to, from, next) {
  if (to.meta.requiredAuth) {
    try {
      let x = await axios.get("users/checkToken", {
        headers: {
          token: localStorage.getItem("token"),
        },
      });
      if (x.data.status == 200) {
        next();
      } else if (x.data.status == 204) {
        next({
          path: "/",
          query: { redirect: to.fullPath },
        });
      } else {
        next();
      }
    } catch (error) {
      localStorage.removeItem("token");
      next({
        path: "/",
        query: { redirect: to.fullPath },
      });
    }
  } else {
    next();
  }
});

export default router;
