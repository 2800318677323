import axios from "@/ip_config";
import moment from "moment";

const apiListProject = {
  namespaced: true,

  state: () => ({
    projects: [],
    loadingP: false,
  }),

  mutations: {
    mutationLoading(state, mutationLoading) {
      state.loadingP = mutationLoading;
    },
    mutationProject(state, mutationProject) {
      state.projects = mutationProject;
    },
  },

  actions: {
    getData({ commit }) {
      commit("mutationLoading", true);
      moment.locale("id");

      let hajin = localStorage.getItem("token");
      let voice = localStorage.getItem("idClient");

      axios
        .get("client/listById/" + voice, {
          headers: {
            token: hajin,
          },
        })
        .then((res) => {
          let payload = res.data.data;
          if (payload[0].namaProject == null || payload[0].namaProject == "") {
            commit("mutationProject", []);
          } else {
            payload.forEach((el, id) => {
              payload[id].updateproject = moment(el.updateproject).format(
                "LLL"
              );

              payload[id].nomer = id + 1;
              commit("mutationProject", payload);
            });
          }
          setTimeout(() => {
            commit("mutationLoading", false);
          }, 1500);
        });
    },
  },
};

export default apiListProject;
